import React, { useEffect, useState } from 'react';
import Heading from '../../../CommonComponents/Heading/Heading';
import Description from '../../../CommonComponents/Description/Description';
import Button from '../../../CommonComponents/Button/Button';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { renderMediaComponent } from '@/components/DesignSystem/ModernTheme/utils/common';

import {
  ButtonContainer,
  MiddleBodyParent,
  TopSection,
  BottomSection,
  LeftContainer,
  RightContainer,
  MiddleBodyVideoBackground,
  BannerContainer,
} from './ModernMiddleBody2.styled';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormWidget } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';

function ModernMiddleBody2(props) {
  const [middleBodyState, setMiddleBodyState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [styleConfig] = useState({
    config: {
      bannerAspectRatio: {
        desktop: 768,
        mobile: 768,
      },
      rotationSpeed: 3,
    },
  });
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (props?.config?.children) {
      setMiddleBodyState(transformWidgetProps(props?.config?.children));
    }
  }, [props?.config?.children]);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const dispatch = useDispatch();
  const openForm = () => {
    dispatch(toggleFormWidget(true));
  };

  useEffect(() => {
    const video = document.querySelector('video');
    if (video) {
      video.setAttribute('playsinline', '');
    }
  }, []);
  const renderParentContainer = (bgType) => {
    return (
      <>
        {(middleBodyState?.heading?.text ||
          middleBodyState?.description?.text ||
          middleBodyState?.button) && (
          <LeftContainer>
            {(middleBodyState?.heading?.text ||
              middleBodyState?.description?.text ||
              middleBodyState?.buttons) && (
              <TopSection textStyles={propStyle}>
                {middleBodyState?.heading?.text && (
                  <Heading headingData={middleBodyState?.heading} />
                )}
                {middleBodyState?.description?.text && (
                  <Description descriptionData={middleBodyState?.description} />
                )}
              </TopSection>
            )}
            {middleBodyState?.button?.content && (
              <BottomSection>
                <ButtonContainer>
                  {middleBodyState?.button?.content.map((btnData, idx) => (
                    <Button
                      key={btnData?.id || idx}
                      btnData={btnData}
                      primaryButton={propStyle?.buttonPrimary}
                      secondaryButton={propStyle?.buttonSecondary}
                      callback={openForm}
                    />
                  ))}
                </ButtonContainer>
              </BottomSection>
            )}
          </LeftContainer>
        )}
        {middleBodyState?.media?.foreground?.content && (
          <RightContainer
            IS_MEDIA_URL={
              middleBodyState?.media?.foreground?.content.length ? true : false
            }
          >
            {renderMediaComponent(middleBodyState?.media?.foreground)}
          </RightContainer>
        )}
      </>
    );
  };
  return middleBodyState?.media?.background?.type === 'video' ? (
    <MiddleBodyVideoBackground
      id="MT__HEROBANNER2"
      background={middleBodyState?.media?.background}
      styleConfig={styleConfig?.config}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebody2 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      {' '}
      <video
        autoPlay
        loop
        muted
        onError={(e) =>
          (e.target.src = middleBodyState?.media?.background?.content[0]?.url)
        }
        playsInline
      >
        <source
          src={middleBodyState?.media?.background?.content[0]?.url}
          type="video/mp4"
        />
      </video>{' '}
      <MiddleBodyParent
        id="MT__HEROBANNER2"
        styleConfig={styleConfig?.config}
        propsBackground={middleBodyState?.media?.background}
        themeBackground={propStyle?.backgroundColor}
        className={`modern_theme_middlebody1 bg_type_${middleBodyState?.media?.background?.type}`}
      >
        <BannerContainer> {renderParentContainer('video')} </BannerContainer>
      </MiddleBodyParent>
    </MiddleBodyVideoBackground>
  ) : (
    <MiddleBodyParent
      id="MT__HEROBANNER2"
      styleConfig={styleConfig?.config}
      propsBackground={middleBodyState?.media?.background}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebody1 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      {middleBodyState?.media?.background?.type === 'image' &&
      middleBodyState?.media?.background?.content[0]?.url.length > 0 ? (
        <img
          className="bg"
          src={middleBodyState?.media?.background?.content[0]?.url}
          onError={(e) =>
            (e.target.src = middleBodyState?.media?.background?.content[0]?.url)
          }
        />
      ) : (
        <></>
      )}
      <BannerContainer> {renderParentContainer('')}</BannerContainer>
    </MiddleBodyParent>
  );
}

export default ModernMiddleBody2;
